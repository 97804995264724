import React from 'react';
// source https://www.svgrepo.com/svg/85064/swirly-arrow-pointing-upwards
function UpArrow({
  title,
  titleId,
  ...props
}, svgRef) {
  return React.createElement('svg', Object.assign({
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: "0 0 400 400",
    "aria-hidden": "true",
    "data-slot": "icon",
    ref: svgRef,
    fill: "#ffffff",
    width: "400px",
    height: "400px",
    "aria-labelledby": titleId
  }, props), 
    title ? React.createElement('title', 
    { id: titleId }, title) : null,
    React.createElement('path', {
      d: "M336.689,48.802c-1.836-5.508-10.403-6.12-11.628,0c-11.016,38.556-33.048,74.052-31.823,115.668c0,5.508,6.731,7.956,10.403,4.284c5.508-4.896,10.404-9.792,14.688-15.3c1.836,60.588,6.731,166.464-78.948,146.269c-4.896-1.225-8.568-3.061-12.24-4.896c20.809-19.584,29.988-47.124,12.24-77.724c-4.896-8.568-17.748-15.912-27.54-10.404c-19.584,9.792-21.42,36.72-18.972,55.692c1.836,13.464,7.344,24.479,15.3,32.436c-18.972,14.076-45.288,20.196-66.708,15.3c-17.748-4.283-28.152-14.688-32.436-28.151c9.792-6.12,18.36-12.24,25.092-18.36c20.808-18.36,39.168-69.156-3.06-75.888c-34.272-6.121-40.392,63.647-38.556,83.844c0,2.448,0.612,4.284,0.612,6.732c-15.912,7.344-33.66,11.016-49.572,7.956C0.09,277.078,3.15,202.414,29.466,179.77c3.06-2.448-1.224-7.956-4.284-5.508c-35.496,26.929-31.212,83.845-1.224,113.833c18.972,18.359,47.124,12.852,71.604,1.224c16.524,46.512,82.008,42.84,121.788,14.076c22.645,14.688,55.08,14.076,78.336-1.225c47.124-31.212,37.944-102.815,35.496-153.612c8.568,10.404,17.137,20.808,26.929,30.6c3.672,3.672,11.628,1.224,11.016-4.284C364.229,130.81,351.378,89.806,336.689,48.802z M107.19,234.238c1.836-9.18,9.792-39.78,25.704-33.048c23.868,10.404,6.732,38.556-3.672,50.796c-6.732,7.344-14.688,14.076-23.868,19.584C103.518,259.33,104.742,246.479,107.19,234.238z M205.11,239.746c1.224-8.567,7.344-30.6,21.42-18.359c6.731,5.508,7.344,17.136,7.344,24.479c0,15.912-6.12,29.376-16.524,40.393C206.334,274.631,202.662,256.271,205.11,239.746zM320.778,130.81c-0.612,0.612-1.225,1.224-1.837,1.836c-3.672,6.732-7.956,11.628-12.852,16.524c3.06-27.54,15.912-53.244,25.092-79.56c9.792,27.54,17.748,55.692,22.032,84.456c-6.732-7.956-13.464-16.524-20.196-25.092C329.346,124.69,322.614,126.526,320.778,130.81z"
    }),
    // React.createElement('path', {
    //   d: "M6,10H9A1,1,0,0,0,9,8H6A1,1,0,0,0,6,10Z"
    // }),
    // React.createElement('path', {
    //   d: "M14,12H6a1,1,0,0,0,0,2h8A1,1,0,0,0,14,12Z"
    // }),
    // React.createElement('path', {
    //   d: "M14,16H6a1,1,0,0,0,0,2h8A1,1,0,0,0,14,16Z"
    // })
  );
}

const UpArrowIcon = React.forwardRef(UpArrow);

export { UpArrowIcon }
